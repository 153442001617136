
import searchService from "@/services/search.service"
import { Search } from "@/models/search"
import LocationInputCombobox from "@/components/base/input/LocationInputCombobox"
import SearchLocationSuggestions from "@/components/search/v2/SearchLocationSuggestions"
import {
    OABALYTICS_INDEX_CLICK,
    OABALYTICS_INDEX_SELECT,
} from "@/enums/oabalytics"
import anime from "animejs"
import structuredDataHomepage from "@/mixins/structuredDataHomepage"

export default {
    name: "Home",
    components: {
        SearchLocationSuggestions,
        LocationInputCombobox,
        FairBanner: () => import("@/components/fair/FairBanner"),
        ChatDemo: () => import("@/components/chat/ChatDemo"),
        OabotOnboardingTeaser: () =>
            import("@/components/onboarding/OabotOnboardingTeaser"),
        AppDownloadSection: () =>
            import("@/components/sections/AppDownloadSection"),
    },
    meta: {
        toolbar: {
            hidden: "smAndDown",
            hideSearch: true,
            persistent: true,
            scrollOffset: 60,
            arbeitgeberRoute: "/arbeitgeber",
        },
        footer: {
            showMobileAuth: true,
        },
    },
    mixins: [structuredDataHomepage],
    middleware: ["no-business", "no-school"],
    async asyncData({ route, store }) {
        const address = await searchService.getNearby()
        let numberOfBusinesses = 0
        let businesses = []
        if (address && address.lat && address.lng) {
            const searchData = {
                category: "Unternehmen",
                channel: "oabat",
                filters: {
                    latitude: address.lat,
                    longitude: address.lng,
                },
            }
            const result = await searchService.performSearch(searchData)
            businesses = result.result.data
            numberOfBusinesses = result.result.total
        }
        await store.dispatch("search/updateFromRoute", { route: route })

        return {
            numberOfBusinesses,
            businesses,
        }
    },
    data: () => ({
        numberOfBusinesses: 0,
        businesses: [],
        fairs: [],
        showAppHint: true,
    }),
    computed: {
        previewBusinesses() {
            return [...this.businesses].slice(0, 3)
        },
        activeFairs() {
            return this.$store.getters["search/jobFairs"]
        },
    },
    async mounted() {
        if (navigator && navigator.userAgent.indexOf("gonative") > -1) {
            this.showAppHint = false
            if (!this.$auth.loggedIn) {
                this.$store.dispatch("account/showAuth")
            }
        }
        await this.$nextTick()
        if (this.$breakpoint.mdAndUp) this.animateDesktop()
        else this.animateMobile()
    },
    methods: {
        onCitySelected(location) {
            this.$oabalytics.trackEvent(OABALYTICS_INDEX_SELECT, location)
            let search = new Search({ type: "apprenticeship", location })
            this.$router.push(search.getRoute())
        },
        trackClick(params) {
            this.$oabalytics.trackEvent(OABALYTICS_INDEX_CLICK, params)
        },
        animateDesktop() {
            anime({
                targets: ".chat-demo-card",
                delay: 600,
                duration: 500,
                easing: "cubicBezier(0.33, 1, 0.68, 1)",
                scale: [0.85, 1],
                translateX: [100, 0],
                translateY: [50, 0],
                opacity: [0, 1],
            })
            anime({
                targets: ".job-fair-banner",
                delay: 1100,
                duration: 500,
                easing: "cubicBezier(0.33, 1, 0.68, 1)",
                scale: [0.85, 1],
                translateY: ["50%", 0],
                opacity: [0, 1],
            })
        },
        animateMobile() {
            anime({
                targets: ".chat-demo-card",
                delay: 800,
                duration: 500,
                easing: "cubicBezier(0.33, 1, 0.68, 1)",
                scale: [0.85, 1],
                translateY: [150, 0],
                opacity: [0, 1],
            })
        },
    },
    head() {
        return {
            title: "Ausbildungsplätze und Schülerpraktika in Deiner Nähe",
            meta: [
                {
                    hid: "description",
                    name: "description",
                    content:
                        "Praktikum oder Ausbildung sind nur einen Chat entfernt. Entdecke die besten Arbeitgeber in deiner Region. Ohne Anschreiben, ohne Stress. Jetzt bewerben!",
                },
                {
                    hid: "og:title",
                    name: "og:title",
                    content:
                        "Ausbildungsplätze und Schülerpraktika in Deiner Nähe",
                },
                {
                    hid: "og:description",
                    name: "og:description",
                    content:
                        "Praktikum oder Ausbildung sind nur einen Chat entfernt. Entdecke die besten Arbeitgeber in deiner Region. Ohne Anschreiben, ohne Stress. Jetzt bewerben!",
                },
            ],
            __dangerouslyDisableSanitizers: ["script"],
            script: this.getStructuredHomepageData(),
        }
    },
}
