// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/backgrounds/landing_page_background@1x.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/backgrounds/landing_page_background@2x.jpg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".main-section[data-v-1cb1767c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:100% 100%;background-size:cover;min-height:calc(100vh - 80px);padding-bottom:60px;position:relative}@media(min-width:1600px){.main-section[data-v-1cb1767c]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}}.main-section .row[data-v-1cb1767c]{max-width:1920px}.main-section .subtitle[data-v-1cb1767c]{line-height:1.4em!important;max-width:600px}@media (min-width:1620px),only screen and (min-width:500px)and (max-width:1120px){.main-section .subtitle span[data-v-1cb1767c]{display:inline-block}}.main-section .location-suggestions[data-v-1cb1767c]{max-width:600px}.row-search[data-v-1cb1767c]{max-width:550px}@media only screen and (min-width:1264px){.row-search[data-v-1cb1767c]{padding-right:min(500px,10%)}}.row-chat[data-v-1cb1767c]{margin-left:auto;margin-right:auto}@media only screen and (min-width:1264px){.row-chat[data-v-1cb1767c]{padding-left:min(500px,10%)}}.row-banner[data-v-1cb1767c]{margin:max(-200px,-30%) 0 max(-350px,-70%)}.oabot-section[data-v-1cb1767c]{background:#000;min-height:100vh;padding-bottom:60px;padding-top:250px}.chat-demo-card[data-v-1cb1767c]{height:max(600px,70vh);max-height:800px;max-width:450px;opacity:0;width:100%}@supports((-webkit-backdrop-filter:blur(30px)) or (backdrop-filter:blur(30px))){.chat-demo-card[data-v-1cb1767c]{-webkit-backdrop-filter:blur(30px);backdrop-filter:blur(30px);background:hsla(0,0%,100%,.4)}}@supports not ((-webkit-backdrop-filter:blur(30px)) or (backdrop-filter:blur(30px))){.chat-demo-card[data-v-1cb1767c]{background:hsla(0,0%,100%,.95)}}.oabot-wrapper[data-v-1cb1767c]{max-width:400px;width:100%}@media only screen and (min-width:960px){.job-fair-banner[data-v-1cb1767c]{opacity:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
