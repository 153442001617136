const OABAT_ADDRESS = {
    "@type": "PostalAddress",
    addressLocality: "Passau",
    postalCode: "94032",
    addressRegion: "Bayern",
    streetAddress: "Innstraße 69b",
    addressCountry: "Deutschland",
}
const OABAT_URL = "https://www.oabat.de"
const OABAT_INSTAGRAM = "https://www.instagram.com/oabat/"
const OABAT_FACEBOOK = "https://www.facebook.com/oabat"

export default {
    methods: {
        getStructuredHomepageData() {
            const baseURL = this.$config.baseURL
            const data = {
                "@context": "http://schema.org",
                "@type": "Organization",
                name: "Oabat",
                legalName: "Oabat GmbH",
                url: baseURL,
                logo: baseURL + require("@/static/icon.png"),
                sameAs: [OABAT_FACEBOOK, OABAT_INSTAGRAM, OABAT_URL],
                description:
                    "Die Stellenb&#246;rse f&#252;r Ausbildung, Sch&#252;lerpraktika und Duales Studium",
                address: OABAT_ADDRESS,
                location: {
                    "@type": "Place",
                    address: OABAT_ADDRESS,
                },
            }
            return [
                {
                    innerHTML: JSON.stringify(data),
                    type: "application/ld+json",
                },
            ]
        },
    },
}
