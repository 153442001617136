
import searchService from "@/services/search.service"
import { Search } from "@/models/search"

export default {
    name: "SearchLocationSuggestions",
    props: {
        /** current search object */
        search: {
            type: Search,
            default: null,
        },
    },
    async fetch() {
        const cities = await searchService.getPopularCities()
        this.popularCities = cities.map((city) => {
            return {
                ...city,
                disabled: this.isCurrentLocation(city),
                route: this.getSearchRoute(city),
            }
        })
    },
    data: () => ({
        popularCities: [],
    }),
    methods: {
        isCurrentLocation(city) {
            return city.slug === this.search?.location?.slug
        },
        getSearchRoute(city) {
            let location = { slug: city.slug }
            let search = new Search({
                type: this.search?.type ?? "apprenticeship",
                hasTraineeship: this.search?.hasTraineeship,
                location,
            })
            return search.getRoute()
        },
        track(city, index) {
            this.$oabalytics.trackEvent("search-location-suggestion-click", {
                index: index,
                city: city.slug,
            })
        },
    },
}
